<template>

<UserLogin/>

</template>

<script>


import UserLogin from "@/components/UserLogin";
export default {
  name: 'LoginView',


  components: {
    UserLogin

  },
  created() {


  },



}
</script>
