<template>
  <div class="mainHeader">
    <div class="container">
      <div class="row  pt-2 pb-2">
        <div class="col-12">
          <button class="btn btn-transparent float-end" type="button" @click="$store.commit('logout')">
            <i class="fa-duotone fa-arrow-right-from-bracket fa-lg"></i></button>
          <h1 class="text-uppercase text-center">
            <template v-if="icon">
              <i class="fa-duotone" :class="icon"></i>
            </template>
            {{ headline }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MainHeader',
  props: {
    headline: String,
    text: String,
    icon: String,
    employee: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="scss">
</style>
