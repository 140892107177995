<template>
  <div class="allWrap d-flex flex-column h-100">
    <div class="mainContent flex-shrink-0">
      <div class="navigation w-100">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-7">
            </div>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <div class="newVersionTest" @click="$store.state.publishNewVersion = false;"
         v-if="$store.state.publishNewVersion">
      <div class="VersionWarningWrap">
        <h2 class="mb-3">Neue Version verfügbar!</h2>
        <a href="/" class="button btn btn-success mb-3">Hier klicken!</a>
        <p class="versionInfo">Version: {{ $store.state.version }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import router from "@/router";

export default {
  name: 'App',
  computed: {
    ...mapGetters(['checkLogin', 'getUser'])
  },
  methods: {
    ...mapActions(['logout', "checkToken", "getApiUrl"]),
    toggleNav() {
      if (this.showNav === false) {
        this.showNav = true;
      } else {
        this.showNav = false;
      }
    },
  },
  beforeMount() {
    this.checkToken();
  },
  async created() {
    await this.$store.dispatch('getApiUrl');
  },
  data() {
    return {
      showNav: false,
      preferences: [
        {
          title: 'Systemrelevante Cookies',
          description: 'Diese Cookies sind für die Funktion der Website notwendig und können nicht deaktiviert werden.',
          items: [{label: 'technische Cookies', value: 'technical', isRequired: true}],
        },
      ]
    }
  },
}
</script>
<style type="scss">
.mainContent {
}



button.showNavButton.d-inline-block.d-lg-none.btn.btn-primary.btn-sm.float-end {
  position: absolute;
  right: 12px;
  top: 23px;
}

.newVersionTest {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  top: 0;
  background: #ffffffe0;
  padding: 20px;
  text-align: center;
  padding-top: 10vh;
}

.ps {
  height: 60vh;
}

.file-uploads.btn {
  padding: 2px !important;
}
</style>
