import { createRouter, createWebHistory } from 'vue-router'

import LoginOldView from '../views/LoginOldView'
import DashboardView from '../views/DashboardView'
import store from '../store/index'
import { useToast } from "vue-toastification";

const toast = useToast();
const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginOldView
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  store.commit("forceSWupdate");

  if (!store.state.loggedInState && to.name !== 'login') {
    next({ name: 'login' })
  }
else {
  next();
  }
});
export default router
