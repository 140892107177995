<template>
  <div class="timeDisplay">
    <p>{{ formattedTime }}</p>
  </div>
</template>

<script>
export default {
  props: {
    setTime: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formattedTime: ''
    };
  },
  mounted() {
    this.calculateTimeDifference();
    this.startTimer();
  },
  beforeUnmount() {
    this.stopTimer();
  },
  methods: {
    calculateTimeDifference() {
      const targetTime = new Date(this.setTime).getTime();
      const currentTime = new Date().getTime();
      const difference = targetTime - currentTime;

      this.updateFormattedTime(difference);
    },
    updateFormattedTime(duration) {
      const isNegative = duration < 0;
      const absDuration = Math.abs(duration);

      const seconds = Math.floor(absDuration / 1000) % 60;
      const minutes = Math.floor(absDuration / (1000 * 60)) % 60;
      const hours = Math.floor(absDuration / (1000 * 60 * 60));

      const formattedHours = this.formatNumber(isNegative ? -hours : hours);
      const formattedMinutes = this.formatNumber(minutes);
      const formattedSeconds = this.formatNumber(seconds);

      this.formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    },
    formatNumber(number) {
      return number.toString().padStart(2, '0');
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.calculateTimeDifference();
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    }
  }
};
</script>
